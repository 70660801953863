<template>
	<section class="container">
		<div>
			<h1>{{ $t('signup.userCreate') }}</h1>
		</div>
		<div>
			<b-form @submit.prevent="onSubmit">
				<b-form-group id="signup-emailForm">
					<div class="row">
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.email') }}
							</label>
							<InputText v-model="user.email" :required="true" />
						</div>
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.password') }}
							</label>
							<InputPassword v-model="password" :required="true" />
						</div>
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.firstName') }}
							</label>
							<InputText v-model="user.firstName" :required="true" />
						</div>

						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.lastName') }}
							</label>
							<InputText v-model="user.lastName" :required="true" />
						</div>
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.cpr') }}
							</label>
							<InputText v-model="user.cpr" :required="true" />
						</div>
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.phone') }}
							</label>
							<InputPhone v-model="user.phone" :required="false" />
						</div>
					</div>

					<div class="row mt-2 mb-2">
						<div class="col-12">
							<hr />
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<h3>Roller</h3>
						</div>
					</div>

					<div class="row">
						<div class="col-auto pr-0">
							<InputCheck v-model="user.role.isAdmin" :flip="true" />
						</div>
						<div class="col-auto pl-0 mt-1">
							<p>{{ $t('global.roles.superAdmin') }}</p>
						</div>
					</div>

					<div class="row">
						<div class="col-auto pr-0">
							<InputCheck v-model="user.role.isAdvisor" :flip="true" />
						</div>
						<div class="col-auto pl-0 mt-1">
							<p>{{ $t('global.roles.advisor') }}</p>
						</div>
					</div>

					<BaseActionButton v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="signupButton" class="mt-2">
						{{ $t('global.create') }}
					</BaseActionButton>
				</b-form-group>
			</b-form>
		</div>
	</section>
</template>
<script>
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputPassword from '@/modules/global/atomComponents/InputPassword.vue'
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'
import InputPhone from '@/modules/global/atomComponents/InputPhone.vue'


import { User as UserModel } from '@/models/user'

export default {
	name: 'Signup',
	components: {
		InputText,
		InputCheck,
		InputPassword,
		InputDropdown,
		InputPhone,
	},
	data() {
		return {
			clicked: false,
			user: new UserModel({}),
			password: '',
		}
	},
	async mounted() {},
	created() {
		this.$emit('updateLoader')
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			try {
				var result = await this.$store.dispatch('auth/signUpAction', {
					userData: this.user,
					password: this.password,
				})
				this.clicked = false
				this.toast('Success', 'Brugeren er nu oprettet', true)
			} catch (error) {
				this.clicked = false
				console.log(error)
				this.toast(error.response.data.code, error.response.data.message, false)
			}
		},
	},
	computed: {
		companyOptions() {
			var options = this.companies.map((item) => {
				return {
					text: item.companyInfo.name,
					value: item.id,
				}
			})
			return options
		},
		companies() {
			var companies = this.$store.getters['company/companies']
			return companies
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/signup.scss';
</style>
