<template>
	<section>
		<b-form-select
			v-model="inputValue"
			:disabled="disabled"
			:options="options"
			class="form-control"
			:required="required"
			:state="isRequiredAndFilled"
			:multiple="multiple"
		>
			<template #first>
				<b-form-select-option :value="null" disabled>{{ placeholder }}</b-form-select-option>
			</template>
		</b-form-select>
	</section>
</template>

<script>
import requiredFieldMixin from '../../../mixins/requiredFieldMixin'

export default {
	name: 'InputDropdown',
	props: ['disabled', 'options', 'value', 'placeholder', 'required', 'multiple'],
	mixins: [requiredFieldMixin],
	computed: {
		inputValue: {
			get() {
				if (this.value == '') {
					return null
				} else {
					return this.value
				}
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<style></style>
