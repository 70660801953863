<template>
  <div>
    <b-form-input
      v-model="inputValue"
      :disabled="disabled"
      :required="required"
      :state="isRequiredAndFilled"
      :placeholder="place"
      type="password"
    ></b-form-input>
  </div>
</template>

<script>
import requiredFieldMixin from "../../../mixins/requiredFieldMixin";

export default {
  name: "UserPasswordInput",
  mixins: [requiredFieldMixin],
  props: {
    value: String,
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    place: {
      type: String,
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>